import React from "react";

export const MiniAppButton = () => {
  return (
    <button
      onClick={() => {
        window.open("https://t.me/SLAV_Coin_Bot");
      }}
      className="px-4 py-2 flex flex-row gap-1 fixed left-0 top-6 border-2 border-[#FFCB1A] items-center rounded-r-full z-50"
      style={{
        background: "linear-gradient(142deg, #525252 -11.91%, #101010 86.32%)",
      }}
    >
      <img src="/logo.png" alt="slav" className="w-5 md:w-10" />
      <span className="font-bold text-sm md:text-xl text-white">
        SLAV MiniApp
      </span>
    </button>
  );
};
