import { motion } from "framer-motion";
import Launch from "./components/Launch";

const Hero = () => {
  return (
    <div className="flex flex-col hero">
      <div className="text-center flex flex-col items-center w-full text-white mt-[120px] md:mt-[66px]">
        <motion.div
          className="hero-heading text-5xl md:text-[120px]"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          SLAV Coin
        </motion.div>
        <div className="flex flex-row gap-2 items-center justify-center">
          <motion.div
            className="hero-subheading font-normal text-base md:text-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Deflationary Cryptocurrency
          </motion.div>
          {/* <motion.img
            src="/images/bnb.png"
            className="h-4 md:h-5"
            alt="bnb"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          /> */}
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row gap-[95px]">
        <div className="w-full md:w-fit flex items-end justify-end">
          <motion.img
            initial={{ opacity: 0, y: 20, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: [1, 1.05, 1] }}
            transition={{ duration: 0.6 }}
            src="/images/slav-image.png"
            alt="slav"
            className="h-[354px] md:h-[638px]"
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2 items-center gap-5 pt-[74px] md:pt-[118px] hero-info">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            className="flex flex-row gap-2 text-3xl md:text-4xl text-center hero-5m"
          >
            5M+ Meme Community Enters WEB3
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.8 }}
            className="flex flex-row gap-4 items-center"
          >
            <img src="/images/mm.png" alt="m" className="h-8" />
            <span className="text-2xl md:text-3xl text-white">
              Exclusive Gopnik Party
            </span>
            <img src="/images/mm.png" alt="m" className="h-8" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1 }}
            className="flex flex-row gap-4 items-center"
          >
            <img src="/images/cap.png" alt="m" className="h-[14px]" />
            <span className="text-2xl md:text-3xl text-white">
              CAPS are Mandatory
            </span>
            <img src="/images/cap.png" alt="m" className="h-[14px]" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: [1, 1.05, 1] }}
            transition={{ duration: 0.6, delay: 1.2 }}
            className="mt-9"
          >
            <Launch />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 1 }}
            animate={{ opacity: 1, y: 0, scale: [1, 1.05, 1] }}
            transition={{ duration: 0.6, delay: 1.3 }}
            className="flex flex-row gap-6 items-center mt-9"
          >
            <a
              href="https://www.instagram.com/slav_coin"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/gradient-ig.png"
                alt="fb"
                className="w-[56px]"
              />
            </a>
            <a href="https://t.me/SLAV_Coin" target="_blank" rel="noreferrer">
              <img
                src="/images/gradient-tg.png"
                alt="fb"
                className="w-[56px]"
              />
            </a>
            <a href="https://x.com/SLAV_Coin" target="_blank" rel="noreferrer">
              <img src="/images/gradient-x.png" alt="fb" className="w-[56px]" />
            </a>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
