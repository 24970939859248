import React, { useRef, useState, useEffect, ChangeEvent } from "react";
import { motion, useInView } from "framer-motion";
import { Stage, Layer, Image as KonvaImage, Rect } from "react-konva";
import Konva from "konva";
import useScreen from "../hooks/useScreen";

interface Images {
  sunburst?: HTMLImageElement;
  hat?: HTMLImageElement;
}

interface Position {
  x: number;
  y: number;
}

interface Size {
  width: number;
  height: number;
}

const Cap: React.FC = () => {
  const ref = useRef(null);
  const inView = useInView(ref, { once: true });
  const [uploadedImage, setUploadedImage] = useState<HTMLImageElement | null>(
    null
  );
  const [images, setImages] = useState<Images>({});
  const [hatRotation, setHatRotation] = useState<number>(0);
  const [hatPosition, setHatPosition] = useState<Position>({
    x: 527 / 6,
    y: 0,
  });
  const [hatSize, setHatSize] = useState<Size>({ width: 387, height: 248 });
  const stageRef = useRef<Konva.Stage>(null);
  const isMobile: boolean = useScreen();

  useEffect(() => {
    const loadImages = () => {
      const sunburst = new window.Image();
      const hat = new window.Image();

      sunburst.src = "/images/sunburst.png";
      hat.src = "/images/hat.png";

      sunburst.onload = () => {
        setImages((prev) => ({ ...prev, sunburst }));
      };
      hat.onload = () => {
        setImages((prev) => ({ ...prev, hat }));
      };
    };
    loadImages();
  }, []);

  useEffect(() => {
    if (isMobile) {
      setHatPosition({ x: 527 / 3, y: 0 });
      setHatSize({ width: 206, height: 132 });
    } else {
      setHatPosition({ x: 527 / 6, y: 0 });
      setHatSize({ width: 387, height: 248 });
    }
  }, [isMobile]);

  const animationVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0, scale: [1, 1.02, 1] },
  };

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new window.Image();
        img.src = e.target?.result as string;
        img.onload = () => {
          setUploadedImage(img);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDownload = () => {
    if (stageRef.current) {
      const uri = stageRef.current.toDataURL();
      const link = document.createElement("a");
      link.href = uri;
      link.download = "gopnik.png";
      link.click();
    }
  };

  const rotateHat = () => {
    // Toggle between rotating by 15 degrees and resetting rotation
    if (hatRotation === 0) {
      setHatRotation(15);
    } else {
      setHatRotation(0);
    }
  };

  const adjustHatSize = (factor: number): void => {
    setHatSize((prevSize) => ({
      width: prevSize.width * factor,
      height: prevSize.height * factor,
    }));
  };

  const increaseHatSize = (): void => adjustHatSize(1.1);
  const decreaseHatSize = (): void => adjustHatSize(0.9);

  return (
    <div className="flex flex-col py-24 cap-section">
      <motion.div
        ref={ref}
        animate={inView ? "visible" : "hidden"}
        variants={animationVariants}
        transition={{ duration: 0.6 }}
        className="text-center text-[#2BA500] text-[32px] md:text-[72px]"
      >
        PUT ON A CAP
      </motion.div>
      <motion.div
        ref={ref}
        animate={inView ? "visible" : "hidden"}
        variants={animationVariants}
        transition={{ duration: 0.6, delay: 0.2 }}
        className="text-center text-white text-base md:text-[32px]"
      >
        Join Squatting Slav by putting on a CAP
      </motion.div>
      <div className="flex flex-col md:flex-row gap-9 pt-16 md:pt-11 justify-center items-center md:items-start image-display">
        <motion.div
          ref={ref}
          animate={inView ? "visible" : "hidden"}
          variants={animationVariants}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="flex justify-center items-start md:h-[640px] md:w-[527px] h-[341.252px] w-[281px] border-4 rounded-2xl sunburst relative overflow-hidden"
        >
          <Stage
            width={527}
            height={640}
            ref={stageRef}
            className="flex items-center"
          >
            <Layer>
              <Rect width={527} height={640} fill="#2BA500" />
              {images.sunburst && (
                <KonvaImage image={images.sunburst} width={527} height={640} />
              )}
              {uploadedImage && (
                <KonvaImage
                  image={uploadedImage}
                  width={527}
                  height={640}
                  draggable
                  className="absolute z-10"
                />
              )}
              {images.hat && (
                <KonvaImage
                  image={images.hat}
                  x={hatPosition.x}
                  y={hatPosition.y}
                  width={hatSize.width}
                  height={hatSize.height}
                  rotation={hatRotation}
                  draggable={true}
                  onDragEnd={(e: Konva.KonvaEventObject<DragEvent>) => {
                    setHatPosition({ x: e.target.x(), y: e.target.y() });
                  }}
                  className="absolute z-30"
                />
              )}
            </Layer>
          </Stage>
          {uploadedImage && (
            <div className="absolute top-0 right-0 m-4 flex flex-col">
              <button
                onClick={rotateHat}
                className="bg-gray-200 p-2 rounded mb-2"
              >
                Flip
              </button>
              <button
                onClick={increaseHatSize}
                className="bg-gray-200 p-2 rounded mb-2"
              >
                +
              </button>
              <button
                onClick={decreaseHatSize}
                className="bg-gray-200 p-2 rounded"
              >
                -
              </button>
            </div>
          )}
        </motion.div>
        <motion.img
          ref={ref}
          animate={inView ? "visible" : "hidden"}
          variants={animationVariants}
          transition={{ duration: 0.6, delay: 0.4 }}
          src="/images/arrow.png"
          alt="arrow"
          className="h-[182.671px] hidden md:block pt-8"
        />
        <motion.div
          ref={ref}
          animate={inView ? "visible" : "hidden"}
          variants={animationVariants}
          transition={{ duration: 0.6, delay: 0.6 }}
          className="flex flex-col items-center md:mt-36 gap-4"
        >
          <label className="flex justify-center items-center border-4 rounded-sm text-[#fff] bg-[#2BA500] text-2xl md:text-3xl px-6 py-4 cursor-pointer -rotate-3">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
            Become True Gopnik
          </label>
          <button
            onClick={handleDownload}
            className="flex justify-center items-center underline text-[#2BA500] text-2xl md:text-3xl -rotate-3"
          >
            Download the cap
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default Cap;
