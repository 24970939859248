import React from "react";

interface CardProps {
  name: string;
  position: string;
  image: string;
  company: string;
  website: string;
  x: string;
  description: string;
}

export const AdvisorCard: React.FC<CardProps> = (props) => {
  return (
    <div className="flex flex-col p-2 md:p-3 gap-2 advisor-card w-[185px] md:w-[271px]">
      <img
        src={props.image}
        alt=""
        className="w-[170px] h-[170px] md:w-[248px] md:h-[248px] rounded-3xl"
      />
      <div className="md:mt-1 flex flex-col px-2">
        <h2 className="advisor-name text-2xl md:text-3xl">{props.name}</h2>
        <div className="flex flex-row gap-1 text-sm md:text-base text-[#595959]">
          <h2 className="font-bold">{props.position}</h2>
          <h2>of</h2>
        </div>
        <a
          href={props.website}
          target="_blank"
          rel="noreferrer"
          className="company-name text-sm md:text-base underline font-semibold underline-offset-2 decoration-current"
        >
          {props.company}
        </a>
        <h3 className="text-xs md:text-base text-[#595959] break-words">
          {props.description}
        </h3>
      </div>
      <a
        href={props.x}
        target="_blank"
        rel="noreferrer"
        className="self-end p-2 md:p-3"
      >
        <img src="/images/card-x.svg" alt="" />
      </a>
    </div>
  );
};
