import React from "react";

interface ButtonProps {
  click: () => void;
}

export const EclipseDomainsButton: React.FC<ButtonProps> = ({ click }) => {
  return (
    <button
      onClick={click}
      className="px-4 py-2 flex flex-row gap-1 fixed left-0 top-20 md:top-24 border-2 border-[#2CEF32] items-center rounded-r-full z-50"
      style={{
        background: "linear-gradient(142deg, #525252 -11.91%, #101010 86.32%)",
      }}
    >
      <img
        src="/images/eclipse-logo.png"
        alt="slav"
        className="w-[78px] md:w-[136px]"
      />
    </button>
  );
};
