export const proofs = [
  {
    id: 1,
    src: "/images/proof-1.png",
    link: "https://www.youtube.com/watch?v=2-8gsWZqDBM",
  },
  {
    id: 2,
    src: "/images/proof-2.png",
    link: "hhttps://www.youtube.com/watch?v=VLW1ieY4Izw",
  },
  {
    id: 3,
    src: "/images/proof-3.png",
    link: "https://www.youtube.com/watch?v=Qif-Qz7NY48",
  },
  {
    id: 4,
    src: "/images/proof-4.png",
    link: "https://www.youtube.com/watch?v=ES8fFBL8mVY",
  },
  {
    id: 5,
    src: "/images/proof-5.png",
    link: "https://www.mirasafety.com/blogs/news/the-history-of-the-slav-squat",
  },
  {
    id: 6,
    src: "/images/proof-6.png",
    link: "https://www.diggitmagazine.com/articles/squatting-slavs-culture-stereotype-or-just-meme",
  },
];

export const videos = [
  {
    id: 7,
    title: "what is gopnik?",
    link: "https://www.youtube.com/watch?v=Qif-Qz7NY48",
  },
  {
    id: 8,
    title: "How to Dance?",
    link: "hhttps://www.youtube.com/watch?v=VLW1ieY4Izw",
  },
  {
    id: 9,
    title: "How to Squat?",
    link: "https://www.youtube.com/watch?v=2-8gsWZqDBM",
  },
  {
    id: 10,
    title: "Russia's Most “HATED” Sub-Culture…",
    link: "https://www.youtube.com/watch?v=ES8fFBL8mVY",
  },
];

export const blogs = [
  {
    id: 11,
    title: "The History of the Slav Squat",
    link: "https://www.mirasafety.com/blogs/news/the-history-of-the-slav-squat",
  },
  {
    id: 12,
    title: "Squatting Slavs: a culture, a stereotype or just a meme?",
    link: "https://www.diggitmagazine.com/articles/squatting-slavs-culture-stereotype-or-just-meme",
  },
  {
    id: 13,
    title:
      "Squatting Slavs: One of the internet’s best-known memes is evolving",
    link: "https://emerging-europe.com/after-hours/squatting-slavs-one-of-the-internets-best-known-memes-is-evolving/",
  },
];

export const advisors = [
  {
    id: 14,
    name: "Saul Pink",
    image: "/images/saul-pink.png",
    position: "Founder and CEO",
    company: "Pinksale.finance",
    description: "The biggest Decentralized Launchpad",
    website: "https://www.pinksale.finance/",
    x: "https://x.com/pinkecosystem",
  },
  {
    id: 15,
    name: "Yann Pink",
    image: "/images/yann-pink.png",
    position: "CBDO",
    company: "Pinksale.finance",
    description: "The biggest Decentralized Launchpad",
    website: "https://www.pinksale.finance/",
    x: "https://twitter.com/Yannpinksale",
  },
  {
    id: 16,
    name: "Emilian",
    image: "/images/emilian.png",
    position: "CEO",
    company: "Sect Bot",
    description: "Reputable KOL and advisor of many successful projects.",
    website: "https://t.me/Emilianscalls",
    x: "https://x.com/Emilianthesect",
  },
];
