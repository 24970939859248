import { useRef } from "react";
import { useInView, motion } from "framer-motion";
import { AdvisorCard } from "./components/AdvisorCard";
import { advisors } from "../../constants";

export const Advisor = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animationVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, scale: [1, 1.1, 1] },
  };
  return (
    <div className="flex flex-col hero gap-9 md:gap-14 p-[80px] w-full items-center">
      <motion.h1
        ref={ref}
        className="advisor-heading text-[40px] md:text-[89px]"
        animate={isInView ? "visible" : "hidden"}
        variants={animationVariants}
        transition={{ duration: 0.4 }}
      >
        Advisors
      </motion.h1>
      <motion.div
        ref={ref}
        animate={isInView ? "visible" : "hidden"}
        variants={animationVariants}
        transition={{ duration: 0.6 }}
        className="flex flex-wrap gap-4 md:gap-6 w-full justify-center items-center"
      >
        {advisors.map((advisor) => (
          <AdvisorCard
            key={advisor.id}
            image={advisor.image}
            description={advisor.description}
            name={advisor.name}
            company={advisor.company}
            website={advisor.website}
            x={advisor.x}
            position={advisor.position}
          />
        ))}
      </motion.div>
    </div>
  );
};
